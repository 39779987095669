<template>
  <Layout>
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
        Principal Admin Registered Successfully!
      </b-alert>
    </div>

    <PageHeader :title="title" :items="items"/>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" style="margin-bottom: 15px">
                <div role="group" class="btn-group"></div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                        style="margin-left: 5px; margin-right: 5px"
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                  striped
                  hover
                  outlined
                  bordered
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
              >
                <template v-slot:cell(Action)="data">
                  <router-link
                      :to="{
                      name: 'principleForm',
                      params: { id: data.item.principleID, type: 'edit' },
                    }"
                      v-if="$can('edit principle')"
                  >
                    <i
                        class="uil uil-edit-alt"
                        title="Edit"
                        style="font-size: 19px"
                    ></i
                    ></router-link>
                  <i v-if="$can('create principal admin')"
                     class="uil-user-plus"
                     style="font-size: 19px; cursor: pointer; color: #f3766a"
                     @click="openModal(data.item.principleID)"
                  ></i>
                </template>

                <template v-slot:head(principleName)>
                  <p class="align-right" style="margin: 0">Principal Name</p>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-lg-addPrincipalEmployee"
        size="lg"
        title="Order Details"
        title-class="font-18"
        hide-footer
    >
      <template v-slot:modal-header>
        <h4 class="modal-title">
          <b>Add Principal User</b>
        </h4>
      </template>

      <form @submit.prevent="submitEmployee" method="post">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label class="form-label" for="formrow-empName-input"
                    >Name *</label
                    >
                    <input
                        type="text"
                        class="form-control"
                        id="formrow-name-input"
                        v-model="empName"
                        :class="{
                        'is-invalid': submitted && $v.empName.$error,
                      }"
                    />
                    <div
                        v-if="submitted && $v.empName.$error"
                        class="invalid-feedback"
                    >
                      <span v-if="!$v.empName.required"
                      >This value is required.</span
                      >
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                        <label>Date of Joining</label>
                        <br />
                        <input type="date" class="form-control" id="formrow-licenseNO-input" v-model="dateJoining" >
                        
                    </div> -->
                  <!--                  <div class="col-md-6">-->
                  <!--                    <label class="form-label" for="formrow-desgination-input"-->
                  <!--                      >Designation *</label-->
                  <!--                    >-->
                  <!--                    <multiselect-->
                  <!--                      v-model="empTypeID"-->
                  <!--                      :options="employeeTypeArr"-->
                  <!--                      track-by="empTypeID"-->
                  <!--                      label="role"-->
                  <!--                      :class="{-->
                  <!--                        'is-invalid': submitted && $v.empTypeID.$error,-->
                  <!--                      }"-->
                  <!--                    ></multiselect>-->
                  <!--                    <div-->
                  <!--                      v-if="submitted && $v.empTypeID.$error"-->
                  <!--                      class="invalid-feedback"-->
                  <!--                    >-->
                  <!--                      <span v-if="!$v.empTypeID.required"-->
                  <!--                        >This value is required.</span-->
                  <!--                      >-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                  <div class="col-6">
                    <label class="form-label" for="formrow-empEmailID-input"
                    >EmailID *</label
                    >
                    <input
                        type="text"
                        class="form-control"
                        id="formrow-empEmailID-input"
                        v-model="empEmailID"
                        :class="{
                        'is-invalid': submitted && $v.empEmailID.$error,
                      }"
                    />
                    <div
                        v-if="submitted && $v.empEmailID.$error"
                        class="invalid-feedback"
                    >
                      <span v-if="!$v.empEmailID.required"
                      >This value is required.</span
                      >
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="form-label">Phone No.*</label>
                    <input
                        class="form-control"
                        type="number"
                        im-insert="true"
                        v-model="phoneNO"
                        :class="{
                        'is-invalid': submitted && $v.phoneNO.$error,
                      }"
                    />

                    <div
                        v-if="submitted && $v.phoneNO.$error"
                        class="invalid-feedback"
                    >
                      <span v-if="!$v.phoneNO.required"
                      >This value is required.</span
                      >
                      <span v-if="!$v.phoneNO.maxLength"
                      >This value is too long. It should have 10 digits</span
                      >
                      <span v-if="!$v.phoneNO.minLength"
                      >This value length is invalid. It should be 10
                        digits</span
                      >
                    </div>
                  </div>

                  <div class="mb-3 col-6">
                    <label> Login Type<sup>*</sup> : </label>
                    <multiselect
                        v-model="loginType"
                        :options="loginTypeArr"
                        :show-labels="false"
                        track-by="id"
                        label="name"
                        :class="{'is-invalid': submitted && $v.loginType.$error,}"
                        @input="loadRoleTypeData()"
                    >
                    </multiselect>
                    <div v-if="submitted && $v.loginType.$error" class="invalid-feedback">
                      <span v-if="!$v.loginType.required">This value is required.</span>
                    </div>
                  </div>
                  <div class="mb-3 col-6">
                    <label> Role<sup>*</sup> :</label>
                    <multiselect
                        v-model="role"
                        :options="roles"
                        :show-labels="false"
                        track-by="id"
                        label="name"
                        :class="{'is-invalid': submitted && $v.loginType.$error,}"
                    >
                    </multiselect>
                    <div v-if="submitted && $v.role.$error" class="invalid-feedback">
                      <span v-if="!$v.role.required">This value is required.</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label>Select Principal Branch </label>
                    <multiselect
                        v-model="branchID"
                        :options="branches"
                        track-by="principleBranchID"
                        label="principleBranchName"
                        :multiple="true"
                        :show-labels="false"
                    ></multiselect>
                  </div>

                </div>
                <div class="row" style="float: right">
                  <b-spinner
                      v-if="loading"
                      class="m-2 col-3"
                      variant="primary"
                      role="status"
                  ></b-spinner>
                  <button
                      type="submit"
                      class="
                      col-3
                      btn btn-themeYellow
                      w-md
                      waves-effect waves-light
                    "
                      :disabled="loading"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

import {
  required,
  email,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);
/**
 * Advanced-table component
 */
export default {
  page: {
    title: "Principal Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {Layout, PageHeader, Multiselect},
  data() {
    return {
      submitted: false,
      tableData: [],
      title: "Principals",
      items: [
        {
          text: "View",
        },
        {
          text: "Principal",
          active: true,
        },
      ],
      employeeTypeArr: [],
      empTypeID: "",
      empName: "",
      empEmailID: "",
      phoneNO: "",
      nowDate: new Date().toISOString().slice(0, 10),
      status: "",
      showDismissibleAlert: false,
      loading: false,
      notificationText: "",
      alertvariant: "",
      dateJoining: "",
      selectedPrincipleID: "", // for modal
      branches: [],
      branchID: [],
      designationType: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "Action",
          sortable: false,
        },
        {
          key: "principleName",
          sortable: true,
        },
        {
          key: "website",
          sortable: true,
        },
        {
          key: "created",
          sortable: true,
        },
      ],
      path: "",
      editRight: "",
      deleteRight: "",
      loginTypeArr: [
        {id: 1, name: "CloudKitch Admin"},
        {id: 2, name: "Restaurant"},
        {id: 3, name: "Corporate"},
        {id: 4, name: "Principle"},
      ],
      loginType: "",
      roles: [],
      role: "",
    };
  },
  validations: {
    empName: {
      required,
    },
    empEmailID: {
      required,
      email,
    },
    phoneNO: {
      required,
      maxLength: maxLength(10),
      minLength: minLength(10),
    },
    loginType: {
      required
    },
    role: {
      required
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.readPrincipleData();
    // this.getEmployeeDesignation();
    this.checkUserRights();
  },

  methods: {
    /**
     * Search the table data with search input
     */
    loadRoleTypeData() {
      if (this.loginType) {
        this.loading = true;
        this.axios
            .get(this.$loggedRole + "/login-type-roles/" + this.loginType.id)
            .then((result) => {
              this.roles = result.data.data;
              this.loading = false;
            });

      }
    },
    checkUserRights() {
      this.path = this.$route.path;
      this.axios.post(this.$loggedRole + "/checkUserRights", {
        empID: this.$storageData.profile.pid,
        empTypeID: this.$storageData.profile.empTypeID,
        path: this.path,
      })
          .then((result) => {
            this.editRight = result.data.data[0].isEdit;
            this.deleteRight = result.data.data[0].isDelete;
          });
    },

    submitEmployee(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        let formData = new FormData();
        /*
                Add the form data we need to submit
            */
        formData.append("emailID", this.empEmailID);
        formData.append("phoneNO", this.phoneNO);
        formData.append("name", this.empName);
        formData.append('login_type', this.loginType.id);
        formData.append('role', JSON.stringify(this.role));
        formData.append("principleBranchID", JSON.stringify(this.branchID));
        formData.append("principleID", this.selectedPrincipleID);
        e.preventDefault();
        this.axios
            .post(this.$loggedRole + "/addPrincipalBranchAdmin", formData)
            .then((result) => {
                this.loading = false;
                this.$swal({
                  text: result.data.message,
                  icon: "success",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000
                });
                setTimeout(() => {
                  this.$root.$emit("bv::hide::modal", "modal-lg-addPrincipalEmployee");
                  this.empName = "";
                  this.empTypeID = "";
                  this.empEmailID = "";
                  this.phoneNO = "";
                  this.branchID = [];
                  this.role = [];
                }, 2000);

            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: error.response.data.message,
                icon: "error",
              });
            });
      }
    },
    getEmployeeDesignation() {
      this.axios
          .post(this.$loggedRole + "/getEmployeeDesignation", {loginTypeID: 4})
          .then((result) => {
            this.employeeTypeArr = result.data.data;
          });
    },
    getPrincipleBranchByPrincipleID() {
      this.axios
          .post(this.$loggedRole + "/getPrincipleBranchByPrincipleID", {
            principleID: this.selectedPrincipleID,
            city: "",
            //isDashboard : 1, // for all dropdown in only dashboards
          })
          .then((response) => {
            this.branches = response.data.data;
            // this.branchID = this.branches[0];
            // this.principleBranchID = this.branchID.principleBranchID;
            // this.getCorporateCafeteria(this.principleBranchID);
            // this.getPrincipleDashboard();
            // this.getPrincipleCorporateBranches(this.principleBranchID);
          });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    readPrincipleData() {
      axios.get(this.$loggedRole + "/viewAllPrinciples").then((response) => {
        //Then injecting the result to datatable parameters.

        this.tableData = response.data.data;
        //console.log((response));
      });
    },
    openModal(principleID) {
      this.$root.$emit("bv::show::modal", "modal-lg-addPrincipalEmployee");
      this.selectedPrincipleID = principleID;
      this.getPrincipleBranchByPrincipleID(this.selectedPrincipleID);
    },
  },
  middleware: "authentication",
};
</script>
<style>
.multiselect__content-wrapper {
  max-height: 150px !important;
}
</style>